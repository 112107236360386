import React from 'react';
import tooling from '../../../constants/json/admin/tooling.json';
import bagType from '../../../constants/json/admin/bagType.json';
import uom from '../../../constants/json/admin/uom.json';
import platform from '../../../constants/json/admin/platform.json';
import CheckSelect from '../../../components/checkSelect/CheckSelect';
import ToggleSwitch from '../../../components/toggleSwitch/ToggleSwitch';
import DecorationPopup from '../../../components/decorationPopup/DecorationPopup';
import LaunchSeason from '../../../components/decorationPopup/LaunchSeason';
import _ from 'lodash';
import { store } from '../../..';

const dynamicObjectData = limit => {
  let JSONobject = [];
  let templst = [];
  for (let i = 0; i <= limit; i < 10 || i >= 900 ? i++ : (i += 5)) {
    if (i === 205) {
      i = 900;
    }
    i === 0
      ? JSONobject.push({ id: i, label: `-${i}`, value: i })
      : i >= 900
      ? templst.push({ id: i, label: i * -1, value: i })
      : JSONobject.push({ id: i, label: i * -1, value: i });
  }
  JSONobject.push({ id: 22, label: 22 * -1, value: 22 });
  JSONobject.sort((a, b) => a.value - b.value);
  JSONobject.unshift(...templst);

  return JSONobject;
};
const dynamicObjectSize = limit => {
  let JSONobject = [];

  for (let i = 0; i <= limit; i < 10 ? i++ : (i += 5)) {
    i === 0
      ? JSONobject.push({ id: i, label: `-${i}`, value: i })
      : JSONobject.push({ id: i, label: i * -1, value: i });
  }
  return JSONobject;
};
export const modifiedData = [];
const config = [
  {
    colId: 'code',
    headerName: 'Code',
    field: 'productCode',
    pinned: 'left',
    suppressSizeToFit: true,
    width: 100,
    suppressMenu: true,
    suppressMovable: true,
    filter: 'agTextColumnFilter',
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    editable: true,
    singleClickEdit: true,
    onCellValueChanged: onCellValueChanged
  },
  {
    colId: 'platform',
    headerName: 'Platform',
    field: 'platform',
    suppressSizeToFit: true,
    pinned: 'left',
    width: 100,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    editable: true,
    singleClickEdit: true,
    cellEditorSelector: function(params) {
      return {
        component: 'agRichSelectCellEditor',
        params: { values: platform.map(obj => obj.value) }
      };
    },
    onCellValueChanged: onCellValueChanged
  },
  {
    colId: 'type',
    headerName: 'Type',
    field: 'bagType',
    suppressSizeToFit: true,
    pinned: 'left',
    width: 100,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    editable: true,
    singleClickEdit: true,
    cellEditorSelector: function(params) {
      return {
        component: 'agRichSelectCellEditor',
        params: { values: bagType.map(obj => obj.value) }
      };
    },
    onCellValueChanged: onCellValueChanged
  },
  {
    colId: 'landedPrice',
    headerName: 'Landed Price',
    field: 'landedPrice',
    width: 110,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressSizeToFit: true,
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellRendererFramework: params => {
      return params && typeof params.value === 'number'
        ? '$' + params.value
        : params && params.value
        ? '$' + parseFloat(params.value.replace('$', '')).toFixed(2)
        : null;
    },
    editable: true,
    singleClickEdit: true,
    onCellValueChanged: onCellValueChanged
  },
  {
    colId: 'airBagPrice',
    headerName: 'Airbag Price',
    field: 'airBagPrice',
    width: 110,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressSizeToFit: true,
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellRendererFramework: params => {
      return params && typeof params.value === 'number'
        ? '$' + params.value
        : params && params.value
        ? '$' + parseFloat(params.value.replace('$', '')).toFixed(2)
        : null;
    },
    editable: true,
    singleClickEdit: true,
    onCellValueChanged: onCellValueChanged
  },
  {
    colId: 'shPrice',
    headerName: 'S&H Price',
    field: 'shPrice',
    width: 110,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressSizeToFit: true,
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellRendererFramework: params => {
      return params && typeof params.value === 'number'
        ? '$' + params.value
        : params && params.value
        ? '$' + parseFloat(params.value.replace('$', '')).toFixed(2)
        : null;
    },
    editable: true,
    singleClickEdit: true,
    onCellValueChanged: onCellValueChanged
  },
  {
    colId: 'sizes',
    headerName: 'Sizes',
    field: 'availableSizes',
    width: 300,
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: 'cell-wrap',
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellRendererFramework: params => {
      return <CheckSelect params={params} menuItems={dynamicObjectData(909)} field="sizes" />;
    }
  },

  {
    colId: 'sampleSizes',
    headerName: 'Sample Sizes',
    field: 'launchProductSampleSizes',
    width: 300,
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: 'cell-wrap',
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellRendererFramework: params => {
      return <CheckSelect params={params} menuItems={dynamicObjectSize(200)} field="sizes" />;
    }
  },
  {
    colId: 'decoration',
    headerName: 'Decoration Name & Price',
    field: 'decoration',
    filter: 'agTextColumnFilter',
    cellRendererFramework: params => {
      return <DecorationPopup params={params} />;
    },
    autoHeight: true,
    width: 350,
    cellClass: 'cell-wrap',
    suppressMenu: true,
    suppressSizeToFit: true,
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false,
      textCustomComparator: (filter, value, filterText) => {
        let filterTextLoweCase = filterText.toLowerCase();

        switch (filter) {
          case 'contains':
            return (
              value &&
              value.find(item => {
                return (
                  (item.decorationName.toLowerCase() + ' - ' + item.decorationPrice).indexOf(filterTextLoweCase) !== -1
                );
              })
            );
          case 'notContains':
            return !(
              value &&
              value.find(item => {
                return (
                  item.decorationName.toLowerCase().indexOf(filterTextLoweCase) !== -1 ||
                  item.decorationPrice.indexOf(filterTextLoweCase) !== -1
                );
              })
            );
          case 'equals':
            return (
              value &&
              value.find(item => {
                return item.decorationName.toLowerCase() + ' - ' + item.decorationPrice === filterTextLoweCase;
              })
            );
          case 'notEqual':
            return (
              value &&
              value.find(item => {
                return item.decorationName.toLowerCase() + ' - ' + item.decorationPrice !== filterTextLoweCase;
              })
            );
          case 'startsWith':
            return (
              value &&
              value.find(item => {
                return (
                  (item.decorationName.toLowerCase() + ' - ' + item.decorationPrice).indexOf(filterTextLoweCase) === 0
                );
              })
            );
          case 'endsWith':
            return (
              value &&
              value.find(item => {
                let actualValue = item.decorationName.toLowerCase() + ' - ' + item.decorationPrice;
                var index = actualValue.lastIndexOf(filterTextLoweCase);
                return index >= 0 && index === actualValue.length - filterTextLoweCase.length;
              })
            );
          default:
            // should never happen
            console.warn('invalid filter type ' + filter);
            return false;
        }
      },
      textFormatter: value => {
        return value;
      }
    },

    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
    //editable: true,
    //onCellValueChanged: onCellValueChanged
  },
  {
    colId: 'handed',
    headerName: 'Handed',
    width: 100,
    field: 'handed',
    filter: false,
    suppressMenu: true,
    suppressSizeToFit: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellRendererFramework: params => {
      return <ToggleSwitch params={params} />;
    }
  },
  {
    colId: 'initialInflation',
    headerName: 'Initial Inflation Pressure',
    field: 'initialInflationPressure',
    cellClass: 'cell-wrap',
    suppressMenu: true,
    suppressSizeToFit: true,
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellRendererFramework: params => {
      return <CheckSelect params={params} menuItems="initialInflationPressure" />;
    }
  },
  {
    colId: 'sheetThickness',
    headerName: 'Sheet Thickness',
    field: 'sheetThickness',
    width: 200,
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: 'cell-wrap',
    cellRendererFramework: params => {
      return <CheckSelect params={params} menuItems="sheetThickness" />;
    },
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'airsoleHeight',
    headerName: 'Maximum Airsole Height',
    width: 250,
    field: 'airsoleHeight',
    autoHeight: true,
    suppressSizeToFit: true,
    suppressMenu: true,
    suppressMovable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellRendererFramework: params => {
      return <CheckSelect params={params} menuItems="airsoleHeight" />;
    }
  },
  {
    colId: 'fabricHeight',
    headerName: 'Fabric Height',
    field: 'fabricHeight',
    width: 150,
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: 'cell-wrap',
    cellRendererFramework: params => {
      return <CheckSelect params={params} menuItems="fabricHeight" />;
    },
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressMenu: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'launchshoe',
    headerName: 'Launch/Signature Product',
    suppressSizeToFit: true,
    field: 'launchShoes',
    cellRendererFramework: params => {
      return <CheckSelect params={params} menuItems="launchShoes" />;
    },
    autoHeight: true,
    cellClass: 'cell-wrap',
    suppressMenu: true,
    suppressMovable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'launchcategory',
    headerName: 'Launch/Signature Category',
    field: 'launchCategories',
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: 'cell-wrap',
    width: 250,
    cellRendererFramework: params => {
      return <CheckSelect params={params} menuItems="launchCategories" />;
    },
    suppressMenu: true,
    suppressMovable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'launchseason',
    headerName: 'Launch Year',
    field: 'launchSeasons',
    suppressSizeToFit: true,
    autoHeight: true,
    cellClass: 'cell-wrap',
    cellRendererFramework: params => {
      return <LaunchSeason params={params} />;
    },
    suppressMenu: true,
    suppressMovable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    editable: true,
    onCellValueChanged: onCellValueChanged
  },
  {
    colId: 'DCSMaterialId',
    headerName: 'DCS MaterialId',
    field: 'dcsMaterialId',
    width: 130,
    suppressSizeToFit: true,
    suppressMenu: true,
    suppressMovable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    editable: true,
    singleClickEdit: true,
    onCellValueChanged: onCellValueChanged
  },
  {
    colId: 'RecycledContent',
    headerName: 'Recycled Content',
    field: 'recycledContent',
    width: 130,
    suppressSizeToFit: true,
    suppressMenu: true,
    suppressMovable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    editable: true,
    singleClickEdit: true,
    onCellValueChanged: onCellValueChanged
  },
  {
    colId: 'Description',
    headerName: 'Description',
    field: 'description',
    width: 180,
    autoHeight: true,
    cellClass: 'cell-wrap',
    suppressSizeToFit: true,
    suppressMenu: true,
    suppressMovable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    editable: true,
    singleClickEdit: true,
    onCellValueChanged: onCellValueChanged
  },
  {
    colId: 'UOM',
    headerName: 'UOM',
    field: 'uom',
    width: 130,
    suppressSizeToFit: true,
    suppressMenu: true,
    suppressMovable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    editable: true,
    singleClickEdit: true,
    cellEditorSelector: function(params) {
      return {
        component: 'agRichSelectCellEditor',
        params: { values: uom.map(obj => obj.value) }
      };
    },
    onCellValueChanged: onCellValueChanged
  },
  {
    colId: 'toolingExist',
    headerName: 'Tooling Exist',
    field: 'toolingExist',
    width: 130,
    suppressSizeToFit: true,
    suppressMenu: true,
    suppressMovable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    editable: true,
    singleClickEdit: true,
    cellEditorSelector: function(params) {
      return {
        component: 'agRichSelectCellEditor',
        params: { values: tooling.map(obj => obj.value) }
      };
    },
    onCellValueChanged: onCellValueChanged
  },
  {
    colId: 'Comments',
    headerName: 'Comments',
    field: 'airbagComments',
    width: 180,
    autoHeight: true,
    cellClass: 'cell-wrap',
    suppressSizeToFit: true,
    suppressMenu: true,
    suppressMovable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    editable: true,
    singleClickEdit: true,
    onCellValueChanged: onCellValueChanged
  },
  {
    colId: 'archiveDate',
    headerName: 'Archival Date',
    field: 'archiveDate',
    width: 130,
    suppressSizeToFit: true,
    suppressMenu: true,
    suppressMovable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'PCXID',
    headerName: 'PCX ID',
    field: 'pcxId',
    suppressSizeToFit: true,
    width: 100,
    suppressMenu: true,
    suppressMovable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    editable: true,
    singleClickEdit: true,
    onCellValueChanged: onCellValueChanged
  },
  {
    colId: 'PDMID',
    headerName: 'PDM ID',
    field: 'pdmId',
    width: 100,
    suppressSizeToFit: true,
    suppressMenu: true,
    suppressMovable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    editable: true,
    singleClickEdit: true,
    onCellValueChanged: onCellValueChanged
  },
  {
    colId: 'active',
    headerName: 'Active',
    suppressSizeToFit: true,
    pinned: 'right',
    field: 'status',
    sort: 'asc',
    cellRendererFramework: params => {
      return <ToggleSwitch params={params} />;
    },
    suppressMenu: true,
    suppressMovable: true,
    suppressFilter: true,
    width: 80,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'action',
    headerName: 'Action',
    suppressSizeToFit: true,
    pinned: 'right',
    field: 'action',
    width: 80,
    cellRendererFramework: params => {
      return (
        <div>
          <i className="icon-size-adjust fas fa-edit" title="Edit"></i>
        </div>
      );
    },
    suppressMenu: true,
    suppressMovable: true,
    filter: false,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  }
];
function onCellValueChanged(params) {
  const state = store.getState();
  let tempData = _.cloneDeep(state.adminInfo.editedData);
  let tempArr = _.cloneDeep(params.data);
  if (params.data.platform === 'equipment' && tempArr.availableSizes && tempArr.availableSizes.length <= 0) {
    delete tempArr.availableSizes;
  }
  var elementIndex = tempData.findIndex(obj => obj.airbagId === params.data.airbagId);
  if (elementIndex !== -1) {
    tempData[elementIndex] = tempArr;
  } else {
    tempData.push(tempArr);
  }
  store.dispatch({ type: 'MASS_UPLOAD_DATA', payload: tempData });
}

export default config;
